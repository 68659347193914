import React from 'react'
import { offplanSold} from '../../../site/urls'
import SearchResultsTemplate from '../../../templates/search-results-template'

const OffplanSoldProperty = (props) => {
    const location = props.location
  return (
    <SearchResultsTemplate
    locationName={offplanSold}
    location={location}
    pCategoryType="new_developments"
    pType="sales"
    pSubPath="sold"
    pStatus="Sold"
  />
  )
}

export default OffplanSoldProperty